import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import pic11 from '../assets/images/pic11.jpg'

const hidden = {
  display: 'none',
}

const Generic = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - How to Enroll</title>
      <meta name="description" content="How to Enroll" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>How to Enroll</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <p>
            If you have the desire, passion and drive to begin a new, exciting
            and rewarding career path, Thanh Le College, School of Cosmetology
            can help. Our career focused programs help driven students prepare
            for career opportunities in the exciting and rewarding Cosmetology
            industry.
          </p>
          <p>Find out how easy it is to jumpstart your career now!</p>
          <ul>
            <li>
              Visit, tour our school and speak with our Admissions Advisor or
            </li>
            <li>
              Contact us at (714) 740 - 2755 to let us help you get started
              quick and easy
            </li>
            <li>
              Request enrollment information via email at: cosmo12875@yahoo.com
            </li>
          </ul>
          <h3>Enrollment Documents:</h3>
          <p>
            To enroll please download, print, and return the completed documents
            below.
          </p>
          <ul className="actions">
            <li style={hidden}>
              <Link className="fit button small" to="/pre-enrollment">
                Pre-Enrollment Form
              </Link>
            </li>
            <li>
              <Link className="fit button small" to="/enrollment-application">
                Enrollment Application
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section id="two">
        <div className="inner">
          <header className="major">
            <h3>Financial Aid Students Cosmetology & Cosmetician</h3>
          </header>
          <ol>
            <li>Must be 18 years of age or older</li>
            <li>Provide valid identification</li>
            <li>Provide a social security card</li>
            <li>Provide legal residency</li>
            <li>
              Provide a copy of High School Diploma, GED or its equivalent{' '}
            </li>
            <li>
              Pass the school’s admission test with a score of 70% or higher{' '}
            </li>
          </ol>
        </div>
      </section>
      <section id="three">
        <div className="inner">
          <header className="major">
            <h3>
              Non Financial Aid Students Cosmetology, Cosmetician, Instructor
              Trainee & Manicuring
            </h3>
          </header>
          <ol>
            <li>Must be 18 years of age or older</li>
            <li>Provide valid identification</li>
            <li>Provide a social security card</li>
            <li>Provide legal residency</li>
            <li>
              Provide a copy of High School Diploma, GED or its equivalent{' '}
            </li>
            <li>
              Pass the school’s admission test with a score of 70% or higher{' '}
            </li>
          </ol>
        </div>
      </section>
      <section id="four">
        <div className="inner">
          <header className="major">
            <h3>Financial Aid Students Cosmetology Instructor Students</h3>
          </header>
          <ol>
            <li>Must be 18 years of age or older</li>
            <li>Provide valid identification</li>
            <li>Provide a social security card</li>
            <li>Provide legal residency</li>
            <li>
              Provide a copy of High School Diploma, GED or its equivalent{' '}
            </li>
            <li>
              Pass the school’s admission test with a score of 70% or higher{' '}
            </li>
          </ol>
        </div>
      </section>
    </div>
  </Layout>
)

export default Generic
